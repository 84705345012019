@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Outfit:wght@100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


.container {
    margin-bottom: 5rem;
}

.header {
    width: 90%;
    word-break: break-all;
    /* 允许在单词（包括中文字符）内断行 */
    white-space: normal;
    /* 允许换行 */
    overflow-wrap: break-word;
    /* 对长单词进行折行处理 */
}

.item {
    height: 22rem;
}

.title {
    margin-top: 2rem;
    text-align: center;
    font-family: "Ubuntu", system-ui;
    font-size: 1.5rem;
    color: #1A2C5E;
}

.description {
    text-align: center;
    font-size: 1rem;
    line-height: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.pic {
    width: 12rem;
    height: 8rem;
}

@media (max-width: 576px) {
    .container {
        width: 100%;
        height: 50vh;
    }

    .header {
        width: 90%;
        word-break: break-all;
        /* 允许在单词（包括中文字符）内断行 */
        white-space: normal;
        /* 允许换行 */
        overflow-wrap: normal !important;
        /* 对长单词进行折行处理 */
    }

    .menu {
        margin-left: 15%;
    }

    .projects {
        margin-top: 1rem;
        margin-left: 0 !important;
        max-width: 100% !important;
    }
}