@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Outfit:wght@100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.container {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    height: 100vh;
    margin-left: 4rem;
}

.title {
    color: #1A2C5E;
    font-family: "Ubuntu", system-ui;
    font-weight: 800;
    font-size: 1.5rem;
}


.left {
    flex-direction: column;
    /* 垂直排列子元素 */
    display: flex;
    flex: 0 0 50%;

    align-items: flex-start;
}

.right {
    position: relative;
    flex: 0 0 50%;
    align-items: flex-start;
}

.tools {
    color: #1A2C5E;
    margin-top: 1rem;
    font-family: "Ubuntu", system-ui;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1rem;
}

.solutions {
    background: linear-gradient(to bottom, #b7b3b3, #090909);
    /* 红色到蓝色的渐变 */
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
    /* 可选：使字体加粗 */
    font-family: "Ubuntu", system-ui;
    font-weight: 800;
    font-size: 1.5rem;
}

.slogan {
    font-family: "Ubuntu", system-ui;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: #5E6B8E;
    margin-top: 5rem;
}

h2 {
    display: flex;
    font-size: 1.5rem;
    text-align: center;
    text-transform: uppercase;
}

h2 span {
    transition: 1.5s;
}

h2:hover span {
    filter: blur(0.2rem);
    opacity: 0;
    /* 放大 */
    transform: scale(2);

}

h2 span:nth-child(1) {
    transition-delay: 0.1s;
}

h2 span:nth-child(2) {
    transition-delay: 0.2s;
}

h2 span:nth-child(3) {
    transition-delay: 0.3s;
}

h2 span:nth-child(4) {
    transition-delay: 0.4s;
}

h2 span:nth-child(5) {
    transition-delay: 0.5s;
}

h2 span:nth-child(6) {
    transition-delay: 0.6s;
}

h2 span:nth-child(7) {
    transition-delay: 0.7s;
}

h2 span:nth-child(8) {
    transition-delay: 0.8s;
}

h2 span:nth-child(9) {
    transition-delay: 0.9s;
}

h2 span:nth-child(10) {
    transition-delay: 1s;
}

h2 span:nth-child(11) {
    transition-delay: 1.1s;
}

h2 span:nth-child(12) {
    transition-delay: 1.2s;
}

.box {
    width: 30rem;
    height: 15rem;
}

.box img {
    width: 100%;
    height: 100%;
    box-shadow: 0 8px 0.5rem rgba(0, 0, 0, 0.2);
    /* 设置阴影 */
    border-radius: 8px;
    /* 可选：为图片添加圆角 */
    transition: box-shadow 0.3s ease;
    /* 可选：添加平滑过渡效果 */
}

.box img:hover {
    box-shadow: 0 16px 1.5rem rgba(0, 0, 0, 0.3);
    /* 鼠标悬停时阴影效果增强 */
}

@media (max-width: 576px) {

    .container {
        display: flex;
        flex-direction: column-reverse !important;
        /* 设置为列方向布局 */
        margin-left: 5%;
        height: auto !important;
    }

    .myphoto {
        width: 100% !important;
        /* margin-bottom: -40rem !important; */
    }

    .right {
        width: 90%;
        padding: 0 0 1rem 0 !important;
        /* 只保留底部一点填充 */
        margin-bottom: 0 !important;
        /* 取消底部外边距 */
        height: auto !important;
        /* 让高度根据内容自动调整 */
        flex: 0 !important;
    }

    .left {
        flex: 0 !important;
        align-items: flex-start !important;
        padding: 0 !important;
    }

    .slogan {
        margin-top: 0rem !important;
    }

    .box {
        width: 100% !important;
        height: 100% !important;
    }
}