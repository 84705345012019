@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


.menu {
    font-family: "Ubuntu", sans-serif !important;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    margin-left: 4%;
}

.content {
    display: flex;
}

.projects {
    margin-left: 6rem;
    width: 50rem;
}


@media (max-width: 576px) {
    .content {
        display: flex;
        flex-direction: column !important;
        /* 设置为列方向布局 */
        margin-left: 0 !important;
        overflow: hidden;
        /* 禁止内容换行 */
        text-overflow: ellipsis;
        /* 使用省略号表示溢出内容 */
        height: auto !important;
    }

    .menu {
        margin-left: 15%;
    }

    .projects {
        margin-top: 1rem;
        margin-left: 0 !important;
        max-width: 100% !important;
    }
}