@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Outfit:wght@100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');




.box {
    font-family: "Ubuntu", system-ui;
    font-weight: 700;
    background: linear-gradient(45deg, #bfe9ff, #D26DCF);
    /* 确保文字颜色透明，让背景渐变显示出来 */
    -webkit-text-fill-color: transparent;
    /* 裁剪背景至文字 */
    -webkit-background-clip: text;
    background-clip: text;
    transition: opacity 3s ease-in;
    opacity: 0;
}

.box1 {
    opacity: 1;
    font-size: 2.5rem;
    /* 初始化为可见 */
}

.box2 {
    opacity: 1;
    font-size: 2.5rem;
    /* 初始化为可见 */
}

.box1,
.box2 {
    /* 在加载时设置为完全透明 */
    opacity: 0;
}

.show {
    opacity: 1;
    /* 当显示时，设置为完全不透明 */
}